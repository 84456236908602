import { Link } from "react-router-dom";

export function Header() {
  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="text-rose-500 font-bold text-xl flex">
            <img className="w-[70px] h-[65px]" src="../loader.png" alt="" />
          </Link>
          
          {/* <div className="hidden md:flex items-center shadow-sm border rounded-full px-4 py-2 hover:shadow-md transition-shadow">
            <button className="px-4 py-1 font-medium border-r">Anywhere</button>
            <button className="px-4 py-1 font-medium border-r">Any week</button>
            <button className="px-4 py-1 text-gray-500">Add guests</button>
            <Button size="icon" variant="ghost" className="rounded-full bg-rose-500 text-white ml-2">
              <Search className="h-4 w-4" />
            </Button>
          </div> */}

          {/* <div className="flex items-center gap-4">
            <Button variant="ghost" className="hidden md:flex">
              Airbnb your home
            </Button>
            <Button variant="ghost" size="icon" className="rounded-full">
              <Globe className="h-4 w-4" />
            </Button>
            <Button variant="outline" className="rounded-full flex items-center gap-2 p-2">
              <Menu className="h-4 w-4" />
              <User className="h-6 w-6" />
            </Button>
          </div> */}
        </div>
      </div>
    </header>
  );
}

