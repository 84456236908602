import { ChevronLeft, Clock, Diamond, Info, Star } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { Separator } from "../components/ui/separator";
import { Textarea } from "../components/ui/textarea";
import { inDate,outDate,totalGuests, totalNightNumber, totalAmount ,cleaningCharge, serviceCharge, discountedPrice, subAmount, nightRate} from "../recoil/atom";
import { useRecoilValue } from "recoil";
import { format } from "date-fns";

function PaymentPage() {
  const startDate = useRecoilValue(inDate)
  const endDate  = useRecoilValue(outDate)
  const Guests = useRecoilValue(totalGuests)
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white px-4 sm:px-6 lg:px-8">
      <main className="container max-w-6xl mx-auto py-6 space-y-6">
        {/* Header */}
        <div className="flex items-center mt-[100px] sm:mt-[100px]">
          <Button
            variant="ghost"
            className="text-lg p-0 mr-2"
            onClick={() => navigate("/confirm-reservation")}
          >
            <ChevronLeft className="mr-2 h-5 w-5" />
          </Button>
          <h1 className="text-3xl font-semibold">Payment</h1>
        </div>
        <div className="flex flex-col md:flex-row md:w-full lg:w-[1200px] gap-8">
          {/* Payment Notice */}
          <div className="w-full md:w-[40%] ">
            <Card>
              <CardContent className="p-4 flex gap-3 items-center ">
                <div className="w-8 h-8 rounded-full bg-red-100 flex items-center justify-center">
                  <Info className="h-5 w-5 text-red-600" />
                </div>
                <p className="md:text-sm text-xs ">
                  <b>
                    Your payment is securely held in Airbnb escrow account and
                    only released to the property owner after you've checked in
                    and confirmed that everything meets your expectations.
                  </b>
                </p>
              </CardContent>
            </Card>

            <div className="justify-between items-center mt-5 mb-5 gap-4 p-4 border rounded-lg shadow-sm bg-white hidden md:flex">
              <div>
                <h4 className="text-base font-semibold">This is a rare find</h4>
                <p className="text-base text-gray-600">
                  Tina's place is usually booked.
                </p>
              </div>
              <Diamond className="text-pink-500 h-6 w-6" />
            </div>

            {/* Property Details */}
            <div className="flex gap-4 md:hidden mt-5">
              <img
                src="/loader.png"
                alt="Historical Center Wooden Studio"
                className="rounded-lg object-cover w-24 h-24"
              />
              <div>
                <h2 className="font-medium">Historical Center Wooden Studio</h2>
                <div className="flex  items-center gap-1 text-sm text-gray-600 mt-1 ">
                  <span>★ 4.96 (231)</span>
                  <span>•</span>
                  <Diamond className="h-4 w-4" />
                  <span>Superhost</span>
                </div>
              </div>
            </div>

            {/* Trip Details */}
            {/* <div className="space-y-4">
              <h3 className="font-semibold text-lg">Your trip</h3>
              {[
                { label: "Dates", value: "Jan 16–17, 2025" },
                { label: "Guests", value: "1 guest" },
              ].map((item, index) => (
                <div className="flex justify-between" key={index}>
                  <div>
                    <p className="font-medium">{item.label}</p>
                    <p className="text-sm text-gray-600">{item.value}</p>
                  </div>
                  <Button variant="ghost" size="sm">
                    Edit
                  </Button>
                </div>
              ))}
            </div> */}
                    <Card>
          <CardContent className="p-4">
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Dates</div>
                <div className="text-sm text-muted-foreground">
                  {format(
                    new Date(startDate),
                    // dates.from
                    "MMM d, yyyy"
                  )}{" "}
                  -{" "}
                  {format(
                    new Date(endDate),
                    // dates.to
                    "MMM d, yyyy"
                  )}
                </div>
              </div>
              <Button variant="link" className="h-auto p-0">
                Edit
              </Button>
            </div>
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Guests</div>
                <div className="text-sm text-muted-foreground">
                  {
                    Guests
                    } {
                      Guests
                       === 1 ? 'guest' : 'guests'
                  }
                </div>
              </div>
              <Button variant="link" className="h-auto p-0">
                Edit
              </Button>
            </div>
          </CardContent>
        </Card>
          </div>
          <ListingCard />
        </div>

        {/* Price Details */}
        <div className="space-y-4 md:hidden">
          <h3 className="font-semibold">Price details</h3>

            <div className="flex justify-between">
              <span></span>
              <span></span>
            </div>
          <Separator />
          <div className="flex justify-between font-semibold">
            <span>Total (RON)</span>
            <span>169.36 lei</span>
          </div>
        </div>

        <div className="divider w-[50%]"></div>

        {/* Payment Method */}
        <div className="space-y-4 md:w-[40%] py-2">
          <h3 className="font-semibold text-lg">Payment Method</h3>
          <p className="text-sm">
            Payment for this reservation is processed via bank transfer.
            Complete your reservation securely by paying with a bank transfer.
            <br />
            <br />
            Your payment is securely held in Airbnb escrow account and only
            released to the property owner after you've checked in and confirmed
            everything meet your expectations.
          </p>
          <br />
          <div className="rounded-lg space-y-1 text-base font-medium">
            <p>Bank Transfer Details</p>
            <br />
            <p>
              RECEIVER NAME: AIRBNB INC.
              <br />
              IBAN: BE49338229394894842938 SWIFT/BIC
              <br />
              Code: BE94983 Reference <br />
              number: BNB4948334
            </p>
            <br />
            <p>
              Please ensure the correct booking reference number is included in
              your transfer to avoid delays in processing your reservation.
            </p>
            <br />
            <p>
              For quicker approval of your reservation, please email a copy of
              the bank tranfer receip to reservation@airbnb.com
            </p>
            <br />
            <p>Reservation Policy</p>
            <br />
            <p>
              * Please note that if payment is not received within 24 hours, the
              reservation will be automatically canceled and made available for
              others to book.
              <br />
              Secure your booking promptly to ensuer your stay!
            </p>
            <br />
          </div>
        </div>

        {/* Message to Host */}
        <div className="space-y-4 w-full md:w-[50%]">
          <h3 className="font-semibold text-xl">Write a message to the host</h3>
          <p className="text-sm">
            Before you can continue, let Tina know a little about your trip and
            why their plae is a good fit.
          </p>
          <div className="flex items-center">
            <img
              src="/avatar1.jpg"
              alt="Avatar"
              className="object-cover w-10 h-10 rounded-full"
            />
            <div className="ml-3">
              <h3 className="font-semibold">Tina</h3>
              <span className="text-gray-500">Joined in 2019</span>
            </div>
          </div>
          <Textarea
            placeholder="Example: 'Hi Tina, my partner and I are going to a friend's wedding and your place is right down the street.'"
            className="min-h-[100px]"
          />
          <div className="flex justify-end">
                <button className="py-2 px-5 bg-blue-500 text-white rounded-sm"
                // onClick={handleEmail}
                >
                  Send
                </button>
              </div>
        </div>

        <BookingPolicy />

        {/* Request to Book Button */}
        <Button
          className="w-full sm:w-[140px] bg-red-500 hover:bg-red-700"
          size="lg"
          onClick={() => {
            navigate("/last-page");
          }}
        >
          Request to book
        </Button>

        {/* Additional Information */}
      </main>
    </div>
  );
}
export default PaymentPage;

function ListingCard() {

  const totalNights = useRecoilValue(totalNightNumber)
  const cleaning = useRecoilValue(cleaningCharge)
  const service = useRecoilValue(serviceCharge)
  const discount = useRecoilValue(discountedPrice)
  const total = useRecoilValue(totalAmount)
  const subTotal = useRecoilValue(subAmount)
  const oneNight = useRecoilValue(nightRate)

const dynamicData = {
    title : "Amstel Nest - an urban retreat for two",
    type : "Room in townhouse",
    rating : 4.96,
    reviews : 531,
    isSuperhost : true,
    pricePerNight : 145.0,
    serviceFee : 24.36,
    currency : "lei",
    night : totalNights
}

  return (
    <Card className="w-full md:w-[50%] mx-auto mt-8 mb-5 md:mt-0 md:mb-0 md:flex">
      <CardContent className="p-4 space-y-4">
        <div className="flex gap-4">
          <div className="flex-shrink-0">
            <img
              src="/room2.png"
              alt={dynamicData.title}
              className="rounded-lg object-cover w-28 h-28"
            />
          </div>
          <div className="space-y-1">
            <h2 className="font-medium text-lg">{dynamicData.title}</h2>
            <p className="text-gray-600">{dynamicData.type}</p>
            <div className="flex md:flex-row flex-col items-center gap-1 text-sm">
              <div className='flex'>
              <Star className="h-4 w-4 fill-current" />
              <span className='ml-2 mr-2'>{dynamicData.rating}</span>
              <span className="text-gray-600">({dynamicData.reviews} reviews)</span>
              </div>
              <div className='flex'>
              {dynamicData.isSuperhost && (
                <>
                  <span className="text-gray-600">•</span>
                  <Diamond className="h-4 w-4 ml-2 mr-2" />
                  <span>Superhost</span>
                </>
              )}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-4 space-y-3">
          <h3 className="text-xl font-semibold">Price details</h3>

          <div className="space-y-2">
            <div className="flex justify-between">
              <a className="underline">
                {oneNight} {dynamicData.currency} × {dynamicData.night} night
              </a>
              <span>
                {subTotal} {dynamicData.currency}
              </span>
            </div>

            <div className="flex justify-between">
              <a href="#" className="underline">
                Airbnb service fee
              </a>
              <span>
                {service} {dynamicData.currency}
              </span>
            </div>

            <div className="flex justify-between text-green-600">
              <a href="#" className="underline">
               Weekly Stay Discount + Coupen discount
              </a>
              <span>
                -{discount} {dynamicData.currency}
              </span>
            </div>

            <div className="flex justify-between">
              <a href="#" className="underline">
                Cleaning fee
              </a>
              <span>
                {cleaning} {dynamicData.currency}
              </span>
            </div>

            <div className="pt-3 border-t flex justify-between font-semibold">
              <span>Total ({dynamicData.currency.toUpperCase()})</span>
              <span>
                {total} {dynamicData.currency}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function BookingPolicy() {
  return (
    <div className="w-full max-w-lg space-y-8 py-6">
      {/* Cancellation Policy Section */}
      <section>
        <h2 className="text-xl font-semibold text-gray-900 mb-2">
          Cancellation policy
        </h2>
        <p className="text-gray-700">
          <span className="font-semibold">
            Free cancellation before Jan 15.{" "}
          </span>
          <span className="text-gray-500">
            After that, the reservation is non-refundable.{" "}
          </span>
          <button className="font-medium text-gray-900 underline">
            Learn more
          </button>
        </p>
      </section>

      {/* Ground Rules Section */}
      <section>
        <h2 className="text-xl font-semibold text-gray-900 mb-2">
          Ground rules
        </h2>
        <p className="text-gray-700 mb-4">
          We ask every guest to remember a few simple things about what makes a
          great guest.
        </p>
        <ul className="space-y-2 text-gray-700">
          <li>• Follow the house rules</li>
          <li>• Treat your Host's home like your own</li>
        </ul>
      </section>

      <div className="max-w-lg space-y-6 py-6">
        {/* Reservation Status Notice */}
        <div className="flex gap-4 items-center">
          <Clock className="h-6 w-6 flex-shrink-0 text-gray-700" />
          <p className="text-gray-700">
            Your reservation won't be confirmed until the Host accepts your
            request (within 24 hours).{" "}
            <span className="text-gray-500">
              You won't be charged until then.
            </span>
          </p>
        </div>

        {/* Terms Agreement Text */}
        <p className="text-sm text-gray-600">
          By selecting the button below, I agree to the{" "}
          <button className="font-semibold text-gray-900 underline">
            Host's House Rules
          </button>
          ,{" "}
          <button className="font-semibold text-gray-900 underline">
            Ground rules for guests
          </button>
          ,{" "}
          <button className="font-semibold text-gray-900 underline">
            Airbnb's Rebooking and Refund Policy
          </button>
          , and that Airbnb can{" "}
          <button className="font-semibold text-gray-900 underline">
            charge my payment method
          </button>{" "}
          if I'm responsible for damage. I agree to pay the total amount shown
          if the Host accepts my booking request. Payment Terms between you and
          Airbnb Payments Luxembourg S.A.
        </p>
      </div>
    </div>
  );
}
